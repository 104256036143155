import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { ErrorBoundary } from 'components';

import { FEATURE } from 'config/feature';
import { sentry } from 'libs/helpers';
import loadable from '@loadable/component';

const Maintenance = loadable(
  () => import(/* webpackChunkName: "Maintenance" */ 'components/layout/maintenance'),
);

sentry.initialize();

ReactDOM.render(
  <>
    <React.StrictMode>
      {FEATURE.ENABLE_MAINTENANCE_MODE ? (
        <Maintenance />
      ) : (
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      )}
    </React.StrictMode>
  </>,
  document.getElementById('root'),
);
