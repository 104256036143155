import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { Refine } from '@pankod/refine-core';
import { notificationProvider } from '@pankod/refine-antd';
import { RefineKbar, RefineKbarProvider } from '@pankod/refine-kbar';
import routerProvider from '@pankod/refine-react-router-v6';

import { Title, Header, Footer, Layout, Sider, OffLayoutArea, ErrorPage } from 'components/layout';

import {
  accessControl,
  authProvider,
  dataProvider,
  resources,
  shrimpHarvestProvider,
} from 'libs/provider';
import { SERVICE } from 'config/service';

import 'dayjs/locale/id';
import 'react-quill/dist/quill.snow.css';
import 'styles/global.less';
import 'styles/antd.less';
import { promoProvider } from 'libs/provider/promoProvider';
import routes from 'routes';
import { FEATURE } from 'config/feature';

import * as Sentry from '@sentry/react';
import DashboardPage from 'pages/dashboard';
import Login from 'pages/login';
import { crmProvider } from 'libs/provider/crmProvider';

import { EfisheryProvider, Stage } from '@efishery/sdk-reactjs';
import { ENV } from '@config/env';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.tz.setDefault('Asia/Jakarta');

const App: React.FC = () => {
  return (
    <EfisheryProvider
      clientId={ENV.AUTH_CLIENT_ID}
      stage={Stage[ENV.APP_ENVIRONMENT as keyof typeof Stage]}
      enableCookieKeys={ENV.ENABLE_COOKIE_KEYS}
      redirectUrl={`${window.location.origin}/auth/callback`}
      failedRedirectUrl={`${window.location.origin}/login`}
    >
      <RefineKbarProvider>
        <Refine
          authProvider={authProvider}
          accessControlProvider={accessControl}
          dataProvider={{
            default: dataProvider(SERVICE.FISH),
            v2_fish_service: dataProvider(SERVICE.FISH_V2),
            support_service: dataProvider(SERVICE.SUPPORT),
            customer_service: dataProvider(SERVICE.CUSTOMER),
            auth_service: dataProvider(SERVICE.AUTH),
            fresh_service: dataProvider(SERVICE.FRESH),
            promo_service: promoProvider(SERVICE.PROMO),
            promo_serviceV2: promoProvider(SERVICE.PROMO_V2),
            b2b_service: dataProvider(SERVICE.B2B),
            crm_service: crmProvider(SERVICE.CRM),
            supply_chain: dataProvider(SERVICE.SUPPLY_CHAIN),
            wms_service: dataProvider(SERVICE.WMS),
            harvest_shrimp: shrimpHarvestProvider(SERVICE.HARVEST_SHRIMP),
          }}
          routerProvider={{
            ...routerProvider,
            routes,
          }}
          resources={resources}
          catchAll={<ErrorPage />}
          notificationProvider={notificationProvider}
          DashboardPage={DashboardPage}
          Footer={Footer}
          Header={Header}
          Layout={Layout}
          LoginPage={Login}
          OffLayoutArea={OffLayoutArea}
          Sider={Sider}
          Title={Title}
          options={{
            warnWhenUnsavedChanges: true,
            syncWithLocation: true,
            disableTelemetry: true,
            reactQuery: {
              devtoolConfig: {
                initialIsOpen: false,
                position: 'bottom-right',
              },
            },
          }}
        >
          <RefineKbar />
        </Refine>
      </RefineKbarProvider>
    </EfisheryProvider>
  );
};

const exportApp = FEATURE.ENABLE_FEATURE_SENTRY_BROWSER_TRACING_WITH_ROUTING
  ? Sentry.withProfiler(App)
  : App;

export default exportApp;
