import { AxiosInstance } from 'axios';
import { stringify } from 'query-string';
import {
  DataProvider,
  CrudOperators,
  CrudFilters,
  CrudSorting,
  getDefaultFilter,
} from '@pankod/refine-core';
import { axiosInstance } from 'libs/utils';
import urlJoin from 'url-join';
import { SERVICE } from 'config/service';
import { isLogicalCrudFilter } from 'libs/helpers';

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case 'ne':
    case 'gte':
    case 'lte':
    case 'in':
      return `_${operator}`;
    case 'contains':
      return '_like';
    case 'nin':
      return '_not_in';
    default:
      return ''; // default "eq"
  }
};

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const _sort: string[] = [];

    sort.forEach(({ field, order }) => {
      _sort.push(`${field} ${order}`);
    });

    return {
      _sort,
    };
  }

  return;
};

const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {};

  if (filters) {
    filters.forEach((filter) => {
      if (isLogicalCrudFilter(filter)) {
        const { field, operator, value } = filter;

        if (field === 'q') {
          queryFilters._q = value;
          return;
        }

        const mappedOperator = mapOperator(operator);
        queryFilters[`${field}${mappedOperator}`] = value;
      }
    });
  }

  return queryFilters;
};

export const convertResourceToEndpoint = (resources: string) => {
  return resources.split('_').join('/').toLocaleLowerCase();
};

function getNestedPropertyWithArray(obj: any, keys: string[]) {
  let currentObj = obj;
  for (const key of keys) {
    currentObj = currentObj[key];
  }
  return currentObj;
}

const dataProvider = (apiUrl: string, httpClient: AxiosInstance = axiosInstance): DataProvider => ({
  getList: async ({ resource, pagination, filters, sort, metaData }) => {
    const endpoint = metaData?.url || convertResourceToEndpoint(resource);
    const url = `${apiUrl}/${endpoint}`;
    const arrayFormat: 'none' | 'comma' = metaData?.arrayFormat;
    const blacklist: string = getDefaultFilter(metaData?.blacklist, filters);

    const filteredFilters = filters?.filter((filter) => {
      if (
        metaData?.blacklist &&
        isLogicalCrudFilter(filter) &&
        filter.field === metaData?.blacklist
      ) {
        return !(blacklist === 'false');
      }

      return true;
    });

    const queryPagination: {
      _page: number;
      _limit: number;
    } = {
      _page: pagination?.current || 1,
      _limit: pagination?.pageSize || 10,
    };

    const queryEmbed = {
      _embed: metaData?.embed,
    };

    const queryFilters = generateFilter(filteredFilters);
    const querySort = generateSort(sort);

    const query: { _embed: any; _page: number; _limit: number; _sort?: string } = {
      ...queryPagination,
      ...queryFilters,
      ...queryEmbed,
    };

    if (querySort) {
      const { _sort } = querySort;
      query._sort = _sort.join(',');
    }

    const config = {
      headers: metaData?.headers,
    };

    const res = await httpClient.get(
      `${url}?${stringify(query, { arrayFormat: arrayFormat || 'none' })}`,
      config,
    );

    const data = metaData?.dataAttrNames
      ? getNestedPropertyWithArray(res?.data, metaData.dataAttrNames)
      : res?.data?.data;
    const total = metaData?.paginationAttrNames
      ? getNestedPropertyWithArray(res?.data, metaData.paginationAttrNames)
      : res?.data?.pagination?.total_elements ?? data?.length;

    return {
      data,
      total,
    };
  },

  getMany: async ({ resource, ids, metaData }) => {
    const endpoint = metaData?.url || convertResourceToEndpoint(resource);
    const config = {
      headers: metaData?.headers,
    };
    const response = await Promise.allSettled(
      ids.map(async (id) => {
        const url = urlJoin(apiUrl, endpoint, id.toString());
        const res = await httpClient.get(url, config);
        const { data } = res.data;
        return data;
      }),
    );

    const data = response.map((res) => (res.status === 'fulfilled' ? res.value : {}));

    return {
      data,
    };
  },

  create: async ({ resource, variables, metaData }) => {
    try {
      const config = {
        headers: metaData?.headers,
      };
      const endpoint = metaData?.url || convertResourceToEndpoint(resource);
      const url = `${apiUrl}/${endpoint}`;

      const res = await httpClient.post(url, variables, config);
      const { data } = res.data;

      if (metaData?.extendedError === 'sync-sq' && data?.message_error) {
        return Promise.reject(data?.message_error);
      }

      return {
        data,
      };
    } catch (e: any) {
      if (e?.message?.includes('synctocore')) {
        e.message = metaData?.errorMessage + e?.message;
      }

      return Promise.reject(e);
    }
  },

  createMany: async ({ resource, variables, metaData }) => {
    const config = {
      headers: metaData?.headers,
    };
    const endpoint = metaData?.url || convertResourceToEndpoint(resource);
    const response = await Promise.all(
      variables.map(async (param) => {
        const res = await httpClient.post(`${apiUrl}/${endpoint}`, param, config);
        const { data } = res.data;

        if (metaData?.extendedError === 'sync-sq' && data?.message_error) {
          return Promise.reject(data?.message_error);
        }

        return data;
      }),
    );

    return { data: response };
  },

  update: async ({ resource, id, variables, metaData }) => {
    const config = {
      headers: metaData?.headers,
    };
    const endpoint = metaData?.url || convertResourceToEndpoint(resource);
    const url = urlJoin(apiUrl, endpoint, id.toString());

    const res = await httpClient.request({
      url,
      method: metaData?.method || 'put',
      data: variables,
      headers: config.headers,
    });
    const { data } = res.data;

    return {
      data,
    };
  },

  updateMany: async ({ resource, ids, variables, metaData }) => {
    const endpoint = metaData?.url || convertResourceToEndpoint(resource);
    const response = await Promise.all(
      ids.map(async (id) => {
        const res = await httpClient.request({
          url: urlJoin(apiUrl, endpoint, id.toString()),
          method: metaData?.method || 'patch',
          data: variables,
          headers: metaData?.headers,
        });
        const { data } = res.data;
        return data;
      }),
    );

    return { data: response };
  },

  getOne: async ({ resource, id, metaData }) => {
    const endpoint = metaData?.url || convertResourceToEndpoint(resource);
    const config = {
      headers: metaData?.headers,
      params: metaData?.params,
    };

    /**
     * This custom api url when edit form is v2 but the url for getting one data not provided
     * and when you force try using v2, the result will be not found
     * @author Fawwaz
     * @since stg-v0.2.45-alpha.9
     */
    let newApiUrl = apiUrl;
    if (resource === 'buyer_lead-outlets') {
      newApiUrl = SERVICE.FISH;
    }

    const url = urlJoin(newApiUrl, endpoint, id.toString());

    const res = await httpClient.get(url, config);
    const { data } = res.data;

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, metaData }) => {
    const endpoint = metaData?.url || convertResourceToEndpoint(resource);
    const config = {
      headers: metaData?.headers,
    };
    const url = urlJoin(apiUrl, endpoint, id.toString());

    const res = await httpClient.delete(url, config);
    const { data } = res.data;

    return {
      data,
    };
  },

  deleteMany: async ({ resource, ids, metaData }) => {
    const endpoint = metaData?.url || convertResourceToEndpoint(resource);
    const config = {
      headers: metaData?.headers,
    };
    const response = await Promise.all(
      ids.map(async (id) => {
        const res = await httpClient.delete(urlJoin(apiUrl, endpoint, id.toString()), config);
        const { data } = res.data;
        return data;
      }),
    );
    return { data: response };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}?`;

    if (sort) {
      const querySort = generateSort(sort);
      if (querySort) {
        requestUrl = `${requestUrl}&${stringify(querySort)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    // if (headers) {
    //   httpClient.defaults.headers = {
    //     ...httpClient.defaults.headers,
    //     ...headers,
    //   };
    // }

    let axiosResponse;
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload, { headers });
        break;
      case 'delete':
        axiosResponse = await httpClient.delete(url, { headers });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl, { headers });
        break;
    }

    const res = axiosResponse;

    return Promise.resolve(res.data);
  },
});

export { dataProvider };
