import { captureException, captureMessage } from '@sentry/react';
import { AxiosError } from 'axios';

import { HttpError } from '@pankod/refine-core';

const reportSentry = (error?: AxiosError<HttpError>) => {
  if (!error?.response) return;

  const { config, status } = error.response;

  const errorMsg = error.response?.data?.metadata?.error?.message || error.response?.data?.message;

  const extra = {
    status,
    url: config.url,
    params: config.params,
    body: config.data,
  };

  const captureContext: Parameters<typeof captureException>[1] = {
    tags: {
      networkError: true,
      networkErrorAxiosCode: error.code,
    },
    extra: {
      ...extra,
      __meta__: btoa(JSON.stringify(extra)).split('').reverse().join(''),
    },
  };

  // Caused by FE
  if (String(status).startsWith('4')) {
    captureException(errorMsg, captureContext);
  }

  // Caused by BE
  if (String(status).startsWith('5')) {
    captureMessage(errorMsg, captureContext);
  }
};

export const captureError = (error?: AxiosError<HttpError>) => {
  try {
    reportSentry(error);
  } catch {
    /* empty */
  }
};
